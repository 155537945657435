<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                @dblclick:row="rowClick"
                class="elevation-1 customization-list-table pagination-list-table sticky-table">

                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.name`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.name">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.contacts`]="{ item }">
                    
                    <div v-if="item.contacts.length > 0" class="onlyReadData more-per-lab-wrap">
                        <v-contact-item
                            v-for="contact in item.contacts"
                            :key="contact.id"
                            :type="contact.type === 'email' ? 'email' : 'phone'"
                            :title="contact.value"
                            :description="contact.description"
                            :hide-load="true"
                            :is-read-only="true"
                        />
                    </div>
                                       
                </template>

                <template v-slot:[`item.headid`]="{ item }">
                    
                    <div v-if="item.headid!='00000000-0000-0000-0000-000000000000'">
                        <v-workplace-chip
                            v-if="item.employeeid"
                            :id="item.headid"
                            :name="item.headname"
                        />
                        <div v-else class="onlyReadData">
                            {{$t("Вакансия")}} 
                        </div>
                    </div>
                        
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system";

export default {
    columnIndex:  {
        'name': 3,
        'bin': 2,
        'contacts': 4
    },
    props: {
        search: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("БИН"), value: 'bin', width: '150px' },
                { text: i18n.t("Наименование"), value: 'name' },
                { text: i18n.t("Контакты"), value: 'contacts', width: '15%', sortable: false  },
                { text: i18n.t("Руководитель"), value: 'headid', width: '15%' },
            ],
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        options: {
            get: function() {
                let op = this.$store.getters['enterprises/enterprises/GetTableOptions'];
                return op;
            },
            set: function(newValue) {
                this.$store.commit('enterprises/enterprises/SET_TABLE_OPTION', newValue);
            }
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search
            }
        }
    },
    methods: {
        resetPage() {
            this.$store.commit('enterprises/enterprises/SET_TABLE_OPTION_PAGE', 1);
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getSerializedFilter()))
                return;

            this.prevTableFilterObject = this.getSerializedFilter();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/ems/enterpriseslist?filter=${this.getSerializedFilter()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
        },
        getSerializedFilter() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr } = this.filter;

            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "name" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 3;

            return JSON.stringify(
            { 
                SearchStr,
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage
            });
        },
        dateFormat: sys.dateFormat,
        async onToolbarClick(event, btn) {
            this[btn.Action](btn);
        },
        rowClick(item, row) {
            this.$store.commit('emsCards/enterpriseCard/SET_ACTIVE_TAB', 0 );
            this.$store.commit('emsCards/enterpriseCard/SET_OPEN_STRUCT_NODE', [] );
            this.$router.push({ name: 'EMSEnterpriseCard', query: { id: this.entities[row.index].id }, params: { id: this.entities[row.index].id } });
        },
        AddEnterprise() {
            this.$router.push({ name: 'EMSEnterpriseCard', query: { id: 'new' }, params: { id: 'new' }});
        }
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>
